import MedicationIcon from '@mui/icons-material/Medication';
import MasksIcon from '@mui/icons-material/Masks';
import BiotechIcon from '@mui/icons-material/Biotech';
import HealingIcon from '@mui/icons-material/Healing';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { Card, CardContent, CardHeader, CardMedia, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import React from 'react';
import { PageTile } from '../../components/PageTitle';
import { PageContent } from '../../components/PageContent';

export const AboutUsPage: React.FC<{}> = () => {
    return <>
        <PageTile title="A Oncovitta" id="about" />
        <PageContent>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8} >
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardContent>
                                <Typography variant='subtitle1' sx={{ fontWeight: 'bold', pb: '2em' }}>
                                    Nossa missão é contribuir com a qualidade de vida do seu Pet!
                                </Typography>
                                <Typography variant='body1'>
                                    Trabalhamos focados na prevenção e diagnóstico precoce de vários tipo de câncer, e tratamentos para melhorar a qualidade de vida e aumentar as chances de cura dos cães e gatos que tanto amamos!
                                </Typography>
                                <Typography variant='body1'>
                                    Estamos preparados para cuidar de você e do seu pet 💕💕
                                </Typography>
                                <Container maxWidth="xl">
                                    <List dense>
                                        <ListItem>
                                            <ListItemIcon>
                                                <MasksIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Consultas" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <BiotechIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Exames" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <HealingIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Cirurgias" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <MedicationIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Eletroquimioterapia" />
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon>
                                                <MedicalServicesIcon />
                                            </ListItemIcon>
                                            <ListItemText primary=" ... e mais" />
                                        </ListItem>
                                    </List>
                                </Container>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ display: { sm: 'none', md: 'block' } }} >
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardMedia src='animals/hello.png' component="img" alt="Filhote acenando" height={"100%"} >

                            </CardMedia>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </PageContent>
    </>
}