import styled from '@emotion/styled';
import { Card, CardContent, CardMedia, Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';

interface TeamCardProps {
    name: string;
    role: string;
    imagePath: string;
    children: JSX.Element;
}

export const TeamCard: React.FC<TeamCardProps> = ({ name, role, imagePath, children }) => {
    return <>
        <Card sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
            alignItems: 'auto'
        }}>
            <Box sx={{ flex: '0 1 auto', margin: '0 -50px 0 0' }}>
                <CardContent sx={{}}>
                    <Typography variant='h6'>
                        {name}
                    </Typography>
                    <Typography variant='subtitle1'>
                        {role}
                    </Typography>
                    <Divider />
                    {children}
                </CardContent>
            </Box>
            <Box sx={{ flex: '0 0 auto', minWidth: '200px', width: '40%' }}>
                <CardMedia component='div'
                    image={imagePath}
                    sx={{
                        width: '100%',
                        minHeight: '400px',
                        boxShadow: '10px -9px 20px 10px white inset'
                    }}
                />
            </Box>

        </Card>
    </>
}