import { AppBar, Drawer, IconButton, Link, List, ListItem, ListItemButton, ListItemText, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import Menu from '@mui/icons-material/Menu'
import * as React from 'react';

interface NavBarProps {
    links: { label: string, href: string }[]
}

const LargeBar: React.FC<NavBarProps> = ({ links }) => {
    return <>
        <Toolbar sx={{ backgroundColor: "#F2F2F2", justifyContent: 'center', overflowX: 'auto', borderBottom: 1, borderColor: 'divider' }}>
            {links.map((link: any) => (
                <Link color="inherit" noWrap variant="subtitle1" href={link.href} sx={{ p: 1, flexShrink: 0, px: 10, fontSize: '1.3em', textDecoration: 'none' }}>
                    {link.label}
                </Link>
            ))}
        </Toolbar>
    </>
}

const SmallDrawer: React.FC<NavBarProps> = ({ links }) => {
    const [openDrawer, setOpenDrawer] = React.useState(false);
    return <>
        <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
            <List>
                {
                    links.map(link => {
                        return <ListItem>
                            <ListItemButton>
                                <Link href={link.href} color="inherit"
                                    onClick={() => setOpenDrawer(false)}>
                                    {link.label}
                                </Link>
                            </ListItemButton>
                        </ListItem>
                    })
                }
            </List>
        </Drawer>
        <Toolbar sx={{ backgroundColor: "#F2F2F2", overflowX: 'auto', borderBottom: 1, borderColor: 'divider' }}>
            <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                <Menu />
            </IconButton>
        </Toolbar>
    </>
}

export const OncoNavBar: React.FC = () => {
    const links = [
        { label: "A Oncovitta", href: "/#about" },
        { label: "Equipe", href: "/#team" },
        { label: "Contatos", href: "/#contact" },
    ]

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));


    return <div className="onco-nav">
        {
            isMobile ? <SmallDrawer links={links} /> : <LargeBar links={links} />
        }
    </div>
}