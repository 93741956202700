import { ListItem, ListItemIcon } from '@mui/material';
import PetsIcon from '@mui/icons-material/Pets';
import React from 'react';

interface PawListItemProps {
    children: React.ReactNode;
}

export const PawListItem: React.FC<PawListItemProps> = ({ children }) => {
    return <ListItem >
        <ListItemIcon><PetsIcon /></ListItemIcon>
        {children}
    </ListItem>
}