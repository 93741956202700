import { Box } from '@mui/system'
import * as React from 'react'

interface PageContentProps {
    children?: JSX.Element;
}

export const PageContent: React.FC<PageContentProps> = ({ children }) => {
    return <>
        <Box sx={{ backgroundColor: '#CBE0E0', pb: 5, mb: 3 }}>
            {children}
        </Box>
    </>
}