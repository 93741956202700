import * as React from 'react';
import { Alert, AlertTitle, Box, Button, Link, Stack, Typography } from '@mui/material';
import { useCookies } from 'react-cookie';
import { MetaCookie } from './MetaCookie';


export const Toaster: React.FC<{}> = () => {
    const [cookies, setCookie] = useCookies(["consent"]);

    function consentAll() {
        setCookie('consent', 'all')
    }

    function consentRequired() {
        setCookie('consent', 'required')
    }

    const consentBox = <>
        <Box sx={{
            position: 'fixed',
            bottom: 16,
            right: 8,
            left: 8,
        }}>
            <Alert severity='info' sx={{
                opacity: 0.95,
                backgroundColor: '#CBE0E0'
            }} >
                <AlertTitle>Usamos <i>cookies</i>!</AlertTitle>
                <Typography sx={{ width: "100%" }}>
                    Utilizamos cookies para melhorar a sua experiência,
                    ao aceitar os cookies você concordará com nossa
                    <Link target={'_blank'} href='/cookie-policy'> política de cookies</Link>.
                </Typography>

                <Stack spacing={2} direction={'row'}>
                    <Button variant='contained' color='primary' onClick={consentAll}>Todos</Button>
                    <Button variant='contained' color='secondary' onClick={consentRequired}>Somente necessários</Button>
                </Stack>
            </Alert>
        </Box>
    </>

    if (!cookies.consent) {
        return consentBox
    }

    return <>
        <MetaCookie />
    </>
}