import { AppBar, Box, Container, Link, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { OncoNavBar } from './OncoNavBar';

interface HeaderProps {

}

export default function Header(props: HeaderProps) {
  return (
    <React.Fragment>
      <Toolbar>
        <Box display={'none'}>
          <Typography component="h1" variant="h3" color="inherit" align="center" noWrap sx={{ flex: 1 }}>
            Oncovitta
          </Typography>
          <Typography component="h2" variant="subtitle1" color="inherit" align="center" noWrap sx={{ flex: 1 }}>
            Oncologia Veterinária
          </Typography>
        </Box>

        <Container sx={{ display: 'flex', justifyContent: 'center' }}>
          <img src="logo.jpeg" aria-label="Logo" alt="Oncovitta Oncologia Veterinária" width={300} />
        </Container>
      </Toolbar>

      <OncoNavBar />

    </React.Fragment>
  );
}