import * as React from "react";
import { Toaster } from "../components/lgpd/Toaster";
import { AboutUsPage } from "./AboutUs/AboutUsPage";
import { ContactPage } from "./Contact/ContactPage";
import { TeamPage } from "./Team/TeamPage";

export const Home: React.FC = () => {
    return <>
        <AboutUsPage />
        <TeamPage />
        <ContactPage />

        <Toaster />
    </>
}