import { List, Paper } from '@mui/material'
import * as React from 'react'
import { PawListItem } from '../../../components/PawListItem'
import { TeamCard } from '../../../components/TeamCard'
import { PersonProps } from '../PersonProps'

export const RafaelaPage: React.FC<PersonProps> = ({ visible }) => {
    return <>
        <Paper sx={{ display: { xs: visible() } }}>
            <TeamCard name='Rafaela' role='Auxiliar' imagePath='/team/rafaela.jpeg'>
                <List>
                    <PawListItem>Cursou técnico em auxiliar veterinário, pela Focal Treinamento em 2018. </PawListItem>
                    <PawListItem>Trabalha na área da veterinária desde 2018.</PawListItem>
                    <PawListItem>Auxilia em diversas funções direcionadas aos pacientes e tutores</PawListItem>
                    <PawListItem>Também é responsável pelo atendimento ao cliente e público em geral.</PawListItem>
                </List>
            </TeamCard>
        </Paper>
    </>
}