import { List, Paper } from '@mui/material'
import * as React from 'react'
import { PawListItem } from '../../../components/PawListItem'
import { TeamCard } from '../../../components/TeamCard'
import { PersonProps } from '../PersonProps'

export const TatianePage: React.FC<PersonProps> = ({ visible }) => {
    return <>
        <Paper sx={{ display: { xs: visible() } }}>
            <TeamCard name='Tatiane Antonioli' role='Oncologia' imagePath='/team/tatiane.jpeg'>
                <List>
                    <PawListItem>Graduada em Medicina Veterinária pela UNIP em 2012;</PawListItem>
                    <PawListItem>Pós-graduada em Oncologia Veterinária pela Universidade Anhembi Morumbi entre 2013 e 2015;</PawListItem>
                    <PawListItem>Residência em Oncologia de Animais de Companhia pela UFRRJ entre 2014 e 2016;</PawListItem>
                    <PawListItem>Aperfeiçoamento em Cirurgia Oncológica e Reconstrutiva de Cães e Gatos pela FUNEP/UNESP entre 2016 e 2017;</PawListItem>
                    <PawListItem>Pós-graduanda em Hematologia Veterinária pela PAV;</PawListItem>
                    <PawListItem>Sócia da Abrovet (Associação Brasileira de Oncologia Veterinária).</PawListItem>
                </List>
            </TeamCard>
        </Paper>
    </>
}