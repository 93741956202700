import { Circle } from '@mui/icons-material';
import { Container, Grid, Tab, Tabs } from '@mui/material';
import React from 'react';
import { PageContent } from '../../components/PageContent';
import { PageTile } from '../../components/PageTitle';
import { TatianePage } from './People/TatianePage';
import { RafaelaPage } from './People/RafaelaPage';
import { LeticiaPage } from './People/LeticiaPage';

export const TeamPage: React.FC<{}> = () => {
    const [selectedTab, setSelectedTab] = React.useState(0);

    const getDisplay = (index: number) => {
        return index === selectedTab ? 'block' : 'none'
    }

    return <>
        <PageTile title="A Equipe" id="team" />
        <PageContent>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Tabs centered value={selectedTab} onChange={(_, v) => setSelectedTab(v)} aria-label="team page">
                            <Tab icon={<Circle />} iconPosition='start' label="Tatiane" />
                            <Tab icon={<Circle />} iconPosition='start' label="Letícia" />
                            <Tab icon={<Circle />} iconPosition='start' label="Rafaela" />
                        </Tabs>
                    </Grid>

                    <Grid item xs={12}>
                        <TatianePage visible={() => getDisplay(0)} />
                        <LeticiaPage visible={() => getDisplay(1)} />
                        <RafaelaPage visible={() => getDisplay(2)} />
                    </Grid>
                </Grid>
            </Container>
        </PageContent>
    </>
}