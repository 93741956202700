import { List, Paper } from '@mui/material'
import * as React from 'react'
import { PawListItem } from '../../../components/PawListItem'
import { TeamCard } from '../../../components/TeamCard'
import { PersonProps } from '../PersonProps'

export const LeticiaPage: React.FC<PersonProps> = ({ visible }) => {
    return <>
        <Paper sx={{ display: { xs: visible() } }}>
            <TeamCard name='Leticia Isidoro' role='Gastroenterologia' imagePath='/team/leticia.jpeg'>
                <List>
                    <PawListItem>Graduação em Medicina Veterinária, FMU - SP</PawListItem>
                    <PawListItem>Residência em Clínica Médica de Pequenos Animais, UFRRJ - RJ</PawListItem>
                    <PawListItem>Pós-graduação em Gastroenterologia Veterinária, UFAPE - SP</PawListItem>
                    <PawListItem>Professora universitária, FAM - SP</PawListItem>
                    <PawListItem>Membro Sócio da ABRAGA</PawListItem>
                </List>
            </TeamCard>
        </Paper>
    </>
}