import { Container, Typography } from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'

interface PageTitleProps {
    title: string;
    id?: string;
}

export const PageTile: React.FC<PageTitleProps> = ({ title, id }) => {
    return <>
        <Box sx={{ backgroundColor: '#8B5092', py: 3, mb: 5 }} id={id}>
            <Container>
                <Typography component="h3" variant="h5" color={'white'}>
                    {title}
                </Typography>
            </Container>
        </Box>
    </>
}