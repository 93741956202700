import { Card, CardContent, Container, Divider, Grid, Link, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import * as React from "react";
import { PageContent } from "../components/PageContent";
import { PageTile } from "../components/PageTitle";
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';

export const CookiePolicy: React.FC = () => {
    return <>
        {/* <AboutUsPage /> */}
        <PageTile title="Política de Cookies" id="cookie" />
        <PageContent>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4">
                                    O que são <i>cookies</i>?
                                </Typography>
                                <Typography variant="body1">
                                    Leia esta politica de <i>cookies</i> com atençao pois contém informaçoes importantes
                                    sobre como usamos e coletamos dados através de <i>cookies</i> para melhorar a sua
                                    experiência.
                                </Typography>

                                <Typography variant="body1">
                                    Cookies são pequenos arquivos gravados no seu <i>browser</i> que ajudam os <i>sites</i> a
                                    manter a salvar preferências do usuário, melhorando a experiência.
                                    Para ter mais detalhes, sugerimos que leia <Link href='https://www.cloudflare.com/pt-br/learning/privacy/what-are-cookies/' target='_blank'>este artigo</Link>.
                                </Typography>

                                <Typography variant="h4">
                                    <i>Cookies</i> que usamos
                                </Typography>

                                <Typography variant="body1">
                                    Utilizamos <i>cookies</i> de dois tipos:
                                    <List dense>
                                        <ListItem>
                                            <ListItemIcon><CookieOutlinedIcon /></ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="subtitle1">
                                                    Preferências (nescessário)
                                                </Typography>
                                                <Typography variant="body2">
                                                    <i>Cookies</i> que utilizamos para armazenar as suas preferências!
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                        <ListItem>
                                            <ListItemIcon><CookieOutlinedIcon /></ListItemIcon>
                                            <ListItemText>
                                                <Typography variant="subtitle1">
                                                    Estatísticas
                                                </Typography>
                                                <Typography variant="body2">
                                                    Estes <i>cookies</i> usamos para estatísticas de visitação do nosso <i>site</i>
                                                </Typography>
                                                <Typography variant="body2">
                                                    Estes <i>cookies</i> de estatística podem enviar dados para nossos parceiros.
                                                </Typography>
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </PageContent>
    </>
}