import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Card, CardContent, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Divider, Link } from '@mui/material';
import React from 'react';
import { PageContent } from '../../components/PageContent';
import { PageTile } from '../../components/PageTitle';
import { LocalPhone } from '@mui/icons-material';

export const ContactPage: React.FC<{}> = () => {
    return <>
        <PageTile title="Agende uma consulta" id="contact" />
        <PageContent>
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item sm={12} md={4}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                            <CardContent>
                                <List dense>
                                    <ListItem>
                                        <ListItemIcon>
                                            <InstagramIcon />
                                        </ListItemIcon>
                                        <ListItemText primary={
                                            <Link href='https://instagram.com/oncovittavet/' target={'_blank'} variant='body1'>@oncovittavet</Link>
                                        } />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemIcon>
                                            <WhatsAppIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="12 99183-4050" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemIcon>
                                            <LocalPhone />
                                        </ListItemIcon>
                                        <ListItemText primary="12 3207-5107" />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ListItemIcon>
                                            <LocationOnIcon />
                                        </ListItemIcon>
                                        <ListItemText secondary="Alameda José Alves De Siqueira Filho, 139 - Vila Betania, São José dos Campos - SP" />
                                    </ListItem>
                                    <Divider />
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item sm={12} md={8}>
                        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', minWidth: 650 }} >
                            <CardContent
                                dangerouslySetInnerHTML={{ __html: `<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14667.740409151464!2d-45.8920826!3d-23.2090356!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf1d2f186b8fcf9f6!2sOncovitta%20-%20Oncologia%20Veterin%C3%A1ria!5e0!3m2!1spt-BR!2sus!4v1645803877375!5m2!1spt-BR!2sus" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>` }}>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        </PageContent>
    </>
}