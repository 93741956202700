import * as React from 'react'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'

export const MetaCookie: React.FC = () => {
    const [cookies] = useCookies(["consent"]);

    useEffect(() => {
        if (cookies.consent === 'all') {
            import('react-facebook-pixel')
                .then((x) => x.default)
                .then((ReactPixel) => {
                    ReactPixel.init('520440093479143') // facebookPixelId
                    ReactPixel.pageView()
                })
        } else {
            console.debug('cookie not consented')
        }
    }, [cookies])

    return <>

    </>
}