import { Divider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Footer from './components/Footer';
import Header from './components/Header';
import { CookiePolicy } from './pages/CookiePolicy';
import { Home } from './pages/Home';

const theme = createTheme({
  typography: {
    subtitle1: {
      fontVariantCaps: 'all-small-caps',
    },
    body1: {
      paddingBottom: '1.5em',
    },
  },
  components: {
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: '#8B5092',
          color: 'white',
        }
      }
    },
  },
});

export default function Blog() {
  return (
    <BrowserRouter>
      <CookiesProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Header />
          <main>
            <Routes>
              <Route path='/cookie-policy' element={<CookiePolicy/>} />
              <Route path='/' element={<Home/>} />
              <Route path='/*' element={<Navigate to="/" />} />
            </Routes>
          </main>
          <Divider light flexItem variant='fullWidth' />
          <Footer />
        </ThemeProvider>
      </CookiesProvider>
    </BrowserRouter>
  );
}
